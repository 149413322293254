import { Switch, Route, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import useAuth from '../contexts/AuthContext';
import React from 'react';

/* Funcion que renderiza los componentes que NO necesitan autenticacion */
function AsyncRoute({ importPath, ...props }) {
    return <Route {...props} component={loadable(importPath)} />
}
/* Funcion que renderiza los componentes que requieren autenticación */
function AuthenticatedRoute(props) {
    const { user } = useAuth();
    if (user === undefined || props.rol !== user.id_rol) return <Redirect to="/login" />;

    return <AsyncRoute {...props} />
}

export default function Router() {
    return (
        <Switch>
            {/*Rutas para usuarios con de rol de Administrador SICO*/}
            <AuthenticatedRoute
                exact
                rol={1}
                path="/parametricas"
                importPath={() => import('../pages/private-views/parametricas/index')}
            />
            {/*Rutas para usuarios con de rol de Administrador Zoonosis*/}
            <AuthenticatedRoute
                exact
                rol={2}
                path="/estados_establecimientos"
                importPath={() => import('../pages/private-views/estados_establecimientos/index')}
            />
            <AuthenticatedRoute
                exact
                rol={2}
                path="/informacion_vacunacion"
                importPath={() => import('../pages/private-views/informacion_vacunacion/index')}
            />
            <AuthenticatedRoute
                exact
                rol={2}
                path="/informacion_esterilizacion"
                importPath={() => import('../pages/private-views/informacion_esterilizacion/index')}
            />
            <AuthenticatedRoute
                exact
                rol={2}
                path="/consulta_establecimientos"
                importPath={() => import('../pages/private-views/consulta_establecimientos/index')}
            />
            <AuthenticatedRoute
                exact
                rol={2}
                path="/consulta_vacunacion"
                importPath={() => import('../pages/private-views/consulta_vacunacion/index')}
            />
            {/*Rutas para usuarios con rol de establecimiento veterinario*/}
            <AuthenticatedRoute
                exact
                rol={3}
                path="/vacunacion"
                importPath={() => import('../pages/private-views/vacunacion/index')}
            />
            <AuthenticatedRoute
                exact
                rol={3}
                path="/esterilizacion"
                importPath={() => import('../pages/private-views/esterilizacion/index')}
            />
            <AuthenticatedRoute
                exact
                rol={3}
                path="/variables"
                importPath={() => import('../pages/private-views/variables_epidemiologicas/index')}
            />
            <AuthenticatedRoute
                exact
                rol={3}
                path="/editar_establecimiento"
                importPath={() => import('../pages/private-views/editar_establecimiento/index')}
            />
            {/*Rutas publicas que no requieren autenticacion*/}
            <AsyncRoute
                exact
                path="/login"
                importPath={() => import('../pages/public-views/login/Login')}
            />
            <AsyncRoute
                exact
                path="/establecimientos"
                importPath={() => import('../pages/public-views/establecimientos/index')}
            />
            <AsyncRoute
                exact
                path="/"
                importPath={() => import('../pages/public-views/registro/index')}
            />
            <AsyncRoute
                exact
                path="/certificado"
                importPath={() => import('../pages/public-views/certificado/index')}
            />

        </Switch>
    )
}